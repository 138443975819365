import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { animated, Transition } from 'react-spring';

import Arrow from '../../svg/arrow.svg';

const Back = props => {
  const { show, color } = props;
  return (
    <Transition
      native={true}
      items={show}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      config={{ delay: 500 }}
    >
      {show => show && (style => (
        <animated.div className="Back" style={style}>
          <AniLink cover to="/" bg={color}>
            <Arrow />
            <span>Précédent</span>
          </AniLink>
        </animated.div>
      ))}
    </Transition>
  );
};

Back.propTypes = {
  show: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  show: state.animation.show,
});

export default connect(mapStateToProps)(Back);
