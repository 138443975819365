import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Service from '../components/services/Service';

import Printing from '../svg/printing.svg';

const Impression = props => {
  const title = 'Impression et découpe vinyle';
  const services = ['Vinyl', 'Plaque PVC', 'Étiquettes'];
  return (
    <Layout>
      <SEO title={title} description="Marougrav imprime vos créations, logos et bien plus encore sur vinyls, plaque en PVC, étiquettes, etc..." keywords={[
        'gravure',
        'mecanique',
        'laser',
        'impression',
        'consommables']} />
      <Service title={title} services={services} images={props.data.allFile.edges} color="#007A9B">
        <Printing />
      </Service>
    </Layout>
  );
};

export const query = graphql`
  {
    allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "print"}}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Impression;
