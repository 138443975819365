import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import { setShow } from '../../actions/animActions';
import { setTriggerVisible } from '../../actions/navActions';

import Back from './Back';

import 'react-image-lightbox/style.css';

class Service extends Component {
  state = {
    index: 0,
    isOpen: false,
  };

  componentDidMount() {
    if (!this.props.show) {
      this.props.setShow(true);
    }
    this.props.setTriggerVisible(false);
  }

  render() {
    const { show, title, services, images, color, children } = this.props;
    const { index, isOpen } = this.state;

    return (
      <Fragment>
        <Back color={color} />
        <section className="Service__background" style={{ backgroundColor: color }}>
          <Transition
            native={true}
            items={show}
            from={{ opacity: 0 }}
            enter={{ opacity: 1, delay: 800 }}
            leave={{ opacity: 0 }}
          >
            {show => show && (style => (
              <animated.section className="Service__container" style={style}>
                <div className="Service__entry">
                  <h1>{title}</h1>
                  {children}
                  <ul className="Service__entry__list">
                    {services.map((item, index) => <li key={index}>{item}</li>)}
                  </ul>
                  <button className="btn btn-white" onClick={() => this.setState({ isOpen: true })}>Voir les réalisations</button>
                </div>
              </animated.section>
            ))}
          </Transition>
        </section>
        {isOpen && (
          <Lightbox
            mainSrc={images[index].node.childImageSharp.fluid.src}
            nextSrc={images[(index + 1) % images.length]}
            prevSrc={images[(index + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => this.setState({ index: (index + images.length - 1) % images.length })}
            onMoveNextRequest={() => this.setState({ index: (index + 1) % images.length })}
          />
        )}
      </Fragment>
    );
  }
}

Service.propTypes = {
  setShow: PropTypes.func.isRequired,
  setTriggerVisible: PropTypes.func.isRequired,

  title: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,

  show: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  show: state.animation.show,
});

export default connect(mapStateToProps, { setShow, setTriggerVisible })(Service);
